var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      staticClass: "personnel-modal",
      attrs: {
        closable: true,
        size: "normal",
        visible: _vm.personnelVisible,
        "mask-closable": false,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _c("a-space", [
            _c("span", [_vm._v("人数分配")]),
            _c("span", { staticClass: "title_span" }, [
              _vm._v(
                "容量剩余" +
                  _vm._s(_vm.dataSource.actualLicenses) +
                  "/" +
                  _vm._s(_vm.dataSource.licenses) +
                  "人"
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "row_title" },
        [
          _c("a-col", { attrs: { span: 12 } }, [_vm._v("企业名称")]),
          _c("a-col", { attrs: { span: 6 } }, [_vm._v("已用容量")]),
          _c("a-col", { attrs: { span: 6 } }, [_vm._v("容量上限")]),
        ],
        1
      ),
      _vm._l(_vm.dataSource.tenantList, function (item) {
        return _c(
          "a-row",
          { key: item.tenantId, staticClass: "row_content" },
          [
            _c("a-col", { attrs: { span: 12 } }, [
              _vm._v(_vm._s(item.tenantName)),
            ]),
            _c("a-col", { attrs: { span: 6 } }, [
              _vm._v(_vm._s(item.actualLicenses)),
            ]),
            _c(
              "a-col",
              { attrs: { span: 6 } },
              [
                _c("Number", {
                  staticClass: "limit_input",
                  attrs: {
                    placeholder: "请输入",
                    disabled:
                      _vm.dataSource.manualAssign === "1" ? false : true,
                  },
                  model: {
                    value: item.licenses,
                    callback: function ($$v) {
                      _vm.$set(item, "licenses", $$v)
                    },
                    expression: "item.licenses",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }