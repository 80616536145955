<template>
  <!-- <div v-if="personnelVisible" class="personnel_table"> -->
  <!-- <div class="point" />
    <div class="personnel_content"> -->
  <Modal
    :closable="true"
    class="personnel-modal"
    :size="'normal'"
    :visible="personnelVisible"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template slot="title">
      <a-space>
        <span>人数分配</span>
        <span class="title_span">容量剩余{{ dataSource.actualLicenses }}/{{ dataSource.licenses }}人</span>
      </a-space>
    </template>
    <a-row class="row_title">
      <a-col :span="12">企业名称</a-col>
      <a-col :span="6">已用容量</a-col>
      <a-col :span="6">容量上限</a-col>
    </a-row>
    <a-row v-for="item in dataSource.tenantList" :key="item.tenantId" class="row_content">
      <a-col :span="12">{{ item.tenantName }}</a-col>
      <a-col :span="6">{{ item.actualLicenses }}</a-col>
      <a-col :span="6">
        <Number v-model="item.licenses" class="limit_input" placeholder="请输入" :disabled="dataSource.manualAssign === '1' ? false:true" />
      </a-col>
    </a-row>
  </Modal>
  <!-- <a-row class="rowBox">
        <a-button :style="{ marginRight: '10px' }" @click="handleCancel"> 取消 </a-button>
        <a-button type="primary" @click="handleOk"> 确认 </a-button>
      </a-row> -->
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import { getLicensesUsage, setTenantLicenses } from '@/services/oneSelfSetting/numberDistribution.js'
import Number from '@/components/CbNumber/index'
import Cookie from 'js-cookie'

export default {
  components: {
    Number,
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      personnelVisible: false,
      dataSource: {}
    }
  },
  watch: {
    personnelVisible: {
      handler: function(val) {
        if (val) {
          this.$nextTick(() => {
            this.getLicense()
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.getLicense()
  },
  methods: {
    open() {
      this.personnelVisible = true
    },
    async getLicense() {
      const res = await getLicensesUsage({ 'hrsaas-token': Cookie.get('hrsaas-token') })
      this.dataSource = res.data
    },
    handleOk() {
      const { customerId, licenses } = this.dataSource
      const params = {
        'hrsaas-token': Cookie.get('hrsaas-token'),
        customerId,
        licenses,
        tenantLicensesList: this.dataSource.tenantList
      }
      setTenantLicenses(params).then(res => {
        if (res.code === 100) {
          this.$message.success('分配成功')
          this.personnelVisible = false
          return true
        }
      })
    },
    handleCancel() {
      this.personnelVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
@keyframes animation {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.personnel_table{
  width: 500px;
  position: absolute;
  top: 5px;
  right: 200px;
  background: #fff;
  transform-origin: 500px 5px;
  box-shadow: 0px 14px 28px 1px rgba(41, 55, 100, 0.09);
  border-radius: 8px;
  padding: 14px 14px 20px;
  animation: animation 0.2s ease-in;
}
.point{
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  background: transparent;
  border-style: solid;
  border-width: 4px;
  transform: rotate(45deg);
  right: -4px;
  border-color: #fff #fff transparent transparent;
  box-shadow: 0px 14px 28px 0px rgba(41, 55, 100, 0.09);
}
  .ant-space{
    width: 100%;
    padding: 0;
    border: 0;
    color: @sc-grey-100;
  }
  .ant-space-item:first-child{
    margin-right: 14px !important;
    font-size: 16px;
  }
  .title_span{
    color: @sc-grey-60;
    font-size: 14px;
  }
  .ant-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 36px;
  }
  .row_title{
    color: @sc-grey-80;
  }
  .row_content{
    padding: 10px 0;
    border-bottom: 1px solid @sc-grey-10;
    color: @sc-grey-100;
    /deep/.ant-col-12{
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .limit_input{
      width: 120px;
      /deep/.ant-input{
        width: 100% !important;
      }
    }
  }
.rowBox{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-space-align-center {
  margin-bottom: 0 !important;
}
</style>

